<template>
  <div v-if="banner" class="create-banner px-5 py-6">
    <div class="d-flex justify-space-between">
      <div class="aside-panel-title">{{ title }}</div>

      <v-btn small icon @click="cancel()">
        <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
      </v-btn>
    </div>

    <v-img :src="getBannerImageUrl(banner.filename)" class="mt-4" />

    <base-form ref="form" v-model="formModel" class="mt-7" refs="form" :configuration="formConfiguration" />

    <div class="mt-10 text-right text--secondary">Создан {{ formattedDate }}</div>

    <div class="d-flex mt-3">
      <v-btn
        :disabled="isPending"
        :loading="pendingTasks.deleteBanner"
        small
        depressed
        color="secondary"
        @click="handleDeleteBanner()"
      >
        Удалить
      </v-btn>

      <v-btn
        class="flex-grow-1 ml-1"
        :disabled="hasNotChanges || isPending"
        :loading="pendingTasks.updateBanner"
        small
        depressed
        color="primary"
        @click="handleUpdateBanner()"
      >
        Сохранить
      </v-btn>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { call, get } from 'vuex-pathify'
  import { isoStringToDisplayDate } from 'geoportal/src/utils/time'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { BannerType } from '@/enums/aquarius'
  import {
    AsidePanelState,
    createBannerFormConfiguration,
    getBannerImageUrl
  } from '@/views/BannersManagementView/bannersManagement'

  export default {
    name: 'UpdateBanner',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        initialFormModel: {},
        formModel: {}
      }
    },

    computed: {
      ...get('bannersManagement', {
        bannerType: 'bannerType',
        banner: 'banner'
      }),

      title() {
        switch (this.bannerType) {
          case BannerType.ADVERTISING:
            return 'Рекламный баннер'
          case BannerType.INFOGRAPHICS:
            return 'Баннер инфографики'
          case BannerType.NEWS:
            return 'Новостной баннер'
        }
        return null
      },

      formConfiguration() {
        return createBannerFormConfiguration(this.bannerType)
      },

      formattedDate() {
        return isoStringToDisplayDate(this.banner.creation_datetime)
      },

      hasNotChanges() {
        return _.isEqual(this.formModel, this.initialFormModel)
      }
    },

    created() {
      this.initialFormModel = _.pick(this.banner, _.map(this.formConfiguration, 'id'))
      this.formModel = _.cloneDeep(this.initialFormModel)
    },

    methods: {
      ...call('bannersManagement', {
        setAsidePanelState: 'setAsidePanelState',
        updateBanner: 'updateBanner',
        deleteBanner: 'deleteBanner'
      }),

      getBannerImageUrl,

      cancel() {
        this.setAsidePanelState([AsidePanelState.BANNERS_LIST])
      },

      @handlePendingTask()
      @handlePendingTask('updateBanner')
      async handleUpdateBanner() {
        if (!this.$refs.form.validate()) {
          return
        }

        await this.updateBanner({ id: this.banner.id, ...this.formModel })

        this.setAsidePanelState([AsidePanelState.BANNERS_LIST])
      },

      @handlePendingTask()
      @handlePendingTask('deleteBanner')
      async handleDeleteBanner() {
        await this.deleteBanner(this.banner.id)

        this.setAsidePanelState([AsidePanelState.BANNERS_LIST])
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
