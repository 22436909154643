<template>
  <!-- eslint-disable vue/no-static-inline-styles -->
  <v-sheet class="page-type-preview d-flex flex-column pa-3" :class="{ 'page-type-preview_active': active }">
    <template v-if="bannerType === BannerType.ADVERTISING">
      <!--<template v-if="pageType === BannerPageType.MAIN_PAGE">-->
      <!--  <div class="d-flex flex-grow-1 justify-space-between align-end">-->
      <!--    <div class="page-type-preview__place" style="width: 30%; height: 80%" />-->
      <!--    <div class="page-type-preview__place" style="width: 30%; height: 30%" />-->
      <!--  </div>-->
      <!--</template>-->

      <!--<template v-else>-->
      <div class="page-type-preview__place mt-auto" style="height: 20%" />
      <!--</template>-->
    </template>

    <!--<template v-else-if="bannerType === BannerType.INFOGRAPHICS">-->
    <!--  <div class="page-type-preview__place" style="width: 60%; height: 60%; margin-top: 15%" />-->
    <!--</template>-->

    <!--<template v-else-if="bannerType === BannerType.NEWS">-->
    <!--  <div class="page-type-preview__place ml-auto" style="width: 40%; height: 60%; margin-top: 15%" />-->
    <!--</template>-->
  </v-sheet>
</template>

<script>
  import { BannerType } from '@/enums/aquarius'
  import { BannerPageType } from '../bannersManagement'

  export default {
    name: 'PageTypePreview',

    props: {
      bannerType: {
        type: String,
        required: true
      },
      pageType: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        BannerType,
        BannerPageType
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .page-type-preview {
    width: 192px;
    height: 100px;
    border: thin solid map-get($material-theme, 'dividers');
    background: transparent;

    .page-type-preview__place {
      border: 2px dashed map-get($guide-colors, 'secondary-1');
      background: white;
    }

    &.page-type-preview_active {
      border-color: map-get($theme-colors, 'primary');
      background: white;

      .page-type-preview__place {
        border-color: map-get($theme-colors, 'primary');
      }
    }
  }
</style>
