<template>
  <div class="banners-list px-5 py-6">
    <div class="d-flex justify-space-between">
      <div class="aside-panel-title">{{ title }}</div>

      <v-btn small icon @click="handleCreateBanner()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <draggable
      :value="banners"
      class="banners-list__list d-flex flex-column mt-4"
      :group="{ name: 'banners', pull: 'clone', put: false }"
      :sort="false"
    >
      <v-img
        v-for="banner of banners"
        :key="banner.id"
        :src="getBannerImageUrl(banner.filename)"
        height="auto"
        eager
        contain
        @click="handleBannerClick(banner)"
      />
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import { get, call } from 'vuex-pathify'
  import { BannerType } from '@/enums/aquarius'
  import { AsidePanelState, getBannerImageUrl } from '@/views/BannersManagementView/bannersManagement'

  export default {
    name: 'BannersList',
    components: { draggable },

    computed: {
      ...get('bannersManagement', {
        bannerType: 'bannerType',
        banners: 'banners'
      }),

      title() {
        switch (this.bannerType) {
          case BannerType.ADVERTISING:
            return 'Рекламные баннеры'
          case BannerType.INFOGRAPHICS:
            return 'Баннеры инфографики'
          case BannerType.NEWS:
            return 'Новостные банеры'
        }
        return null
      }
    },

    methods: {
      ...call('bannersManagement', {
        setAsidePanelState: 'setAsidePanelState'
      }),

      getBannerImageUrl,

      handleCreateBanner() {
        this.setAsidePanelState([AsidePanelState.CREATE_BANNER])
      },

      handleBannerClick(banner) {
        this.setAsidePanelState([AsidePanelState.UPDATE_BANNER, banner.id])
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .banners-list {
    .banners-list__list {
      row-gap: 12px;

      .v-image {
        cursor: move;
        border: thin solid map-get($material-theme, 'dividers');

        &:hover {
          border-color: map-get($theme-colors, 'primary');
        }
      }
    }
  }
</style>
