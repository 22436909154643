<template>
  <div class="create-banner px-5 py-6">
    <div class="d-flex justify-space-between">
      <div class="aside-panel-title">{{ title }}</div>

      <v-btn small icon @click="cancel()">
        <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
      </v-btn>
    </div>

    <image-selector v-model="selectedImage" class="mt-4" />

    <base-form ref="form" v-model="formModel" class="mt-7" refs="form" :configuration="formConfiguration" />

    <v-btn
      class="mt-10 w-100"
      :disabled="!selectedImage || isPending"
      :loading="isPending"
      small
      depressed
      color="primary"
      @click="save()"
    >
      Добавить
    </v-btn>
  </div>
</template>

<script>
  import { call, get } from 'vuex-pathify'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { BannerType } from '@/enums/aquarius'
  import { showMessage } from '@/utils/messages'
  import { AsidePanelState, createBannerFormConfiguration } from '@/views/BannersManagementView/bannersManagement'
  import ImageSelector from './ImageSelector'

  export default {
    name: 'CreateBanner',
    components: { ImageSelector },
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        selectedImage: null,
        formModel: {}
      }
    },

    computed: {
      ...get('bannersManagement', {
        bannerType: 'bannerType'
      }),

      title() {
        switch (this.bannerType) {
          case BannerType.ADVERTISING:
            return 'Новый рекламный баннер'
          case BannerType.INFOGRAPHICS:
            return 'Новый баннер инфографики'
          case BannerType.NEWS:
            return 'Новый новостной баннер'
        }
        return null
      },

      formConfiguration() {
        return createBannerFormConfiguration(this.bannerType)
      }
    },

    methods: {
      ...call('bannersManagement', {
        setAsidePanelState: 'setAsidePanelState',
        createBanner: 'createBanner'
      }),

      cancel() {
        this.setAsidePanelState([AsidePanelState.BANNERS_LIST])
      },

      @handlePendingTask()
      async save() {
        if (!this.selectedImage || !this.$refs.form.validate()) {
          return
        }

        await this.createBanner({ file: this.selectedImage, ...this.formModel })
        showMessage('Баннер успешно создан')

        this.setAsidePanelState([AsidePanelState.BANNERS_LIST])
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
