<template>
  <div class="image-selector">
    <input
      ref="browseButton"
      class="d-none"
      type="file"
      accept="image/gif,image/jpeg,image/png"
      @change="handleFileChange"
    />

    <v-img :key="imageSrc" :src="imageSrc" :height="imageSrc ? 'auto' : 150" contain>
      <template #placeholder>
        <div class="image-placeholder d-flex justify-center align-center fill-height">
          <v-icon>mdi-image</v-icon>
        </div>
      </template>
    </v-img>

    <div class="d-flex mt-8">
      <v-btn x-large depressed color="guideSecondary1" dark @click="handleClick()">Загрузить файл</v-btn>

      <div class="d-flex flex-column justify-center ml-5 text-sm">
        <div>
          <v-icon small left>mdi-check-circle</v-icon>
          До {{ formatFileSize(MAX_FILE_SIZE) }}
        </div>

        <div>
          <v-icon small left>mdi-check-circle</v-icon>
          GIF, JPEG, PNG
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatFileSize } from 'geoportal/src/utils/common'
  import { showErrorMessage } from '@/utils/messages'

  const MAX_FILE_SIZE = 500 * 1000

  export default {
    name: 'ImageSelector',

    props: {
      value: {
        type: File,
        default: null
      }
    },

    data() {
      return {
        MAX_FILE_SIZE,

        imageSrc: null
      }
    },

    watch: {
      value: {
        handler() {
          this.imageSrc = this.value ? URL.createObjectURL(this.value) : null
        },
        immediate: true
      }
    },

    methods: {
      formatFileSize,

      input(value) {
        this.$emit('input', value)
      },

      handleFileChange(event) {
        const file = [...event.target.files][0] ?? null

        if (file && file.size > MAX_FILE_SIZE) {
          showErrorMessage(`Размер изображения не должен превышать ${formatFileSize(MAX_FILE_SIZE)}`)
          return
        }

        this.input(file)
      },

      handleClick() {
        this.$refs.browseButton.click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .image-selector {
    .v-image {
      border: thin solid map-get($material-theme, 'dividers');
    }

    .image-placeholder {
      background: #c4c4c4;

      .v-icon {
        font-size: 56px;
        color: rgba(black, 0.3);
      }
    }
  }
</style>
