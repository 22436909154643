<template>
  <div class="page-layout d-flex flex-column">
    <div class="page-layout__container flex-shrink-0" />

    <banners-drop-area :value="value[placeType]" class="flex-grow-1" size-hint="970×90" @input="input" />
  </div>
</template>

<script>
  import BannersDropArea from '../BannersDropArea'

  export default {
    name: 'PageWithFooterBannerLayout',
    components: { BannersDropArea },

    props: {
      value: {
        type: Object,
        required: true
      },
      placeType: {
        type: String,
        required: true
      }
    },

    methods: {
      input(placeValue) {
        const value = { ...this.value }
        value[this.placeType] = placeValue
        this.$emit('input', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-layout {
    gap: 24px;

    .page-layout__container {
      height: 300px;
    }
  }
</style>
