<template>
  <div class="banners-management d-flex flex-grow-1 fill-height">
    <banners-layout />
    <aside-panel />
  </div>
</template>

<script>
  import { call } from 'vuex-pathify'
  import BannersLayout from './BannersLayout/BannersLayout'
  import AsidePanel from './AsidePanel/AsidePanel'

  export default {
    name: 'BannersManagementView',
    components: { BannersLayout, AsidePanel },

    created() {
      this.init()
    },

    methods: {
      ...call('bannersManagement', {
        init: 'init'
      })
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
