<template>
  <v-sheet
    class="aside-panel flex-shrink-0 d-flex flex-column overflow-y-auto thin-scrollbar"
    tag="aside"
    color="white"
  >
    <banners-list v-if="asidePanelState === AsidePanelState.BANNERS_LIST" />
    <create-banner v-else-if="asidePanelState === AsidePanelState.CREATE_BANNER" />
    <update-banner v-else-if="asidePanelState === AsidePanelState.UPDATE_BANNER" />
  </v-sheet>
</template>

<script>
  import { get } from 'vuex-pathify'
  import BannersList from '@/views/BannersManagementView/AsidePanel/BannersList'
  import { AsidePanelState } from '@/views/BannersManagementView/bannersManagement'
  import CreateBanner from '@/views/BannersManagementView/AsidePanel/CreateBanner/CreateBanner'
  import UpdateBanner from '@/views/BannersManagementView/AsidePanel/UpdateBanner'

  export default {
    name: 'AsidePanel',
    components: { BannersList, CreateBanner, UpdateBanner },

    data() {
      return {
        AsidePanelState
      }
    },

    computed: {
      ...get('bannersManagement', {
        asidePanelState: 'asidePanelState'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .aside-panel {
    width: 420px;
    height: 100%;
    box-shadow: -2px 6px 9px rgba(0, 0, 0, 0.25);
  }
</style>
