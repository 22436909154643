<template>
  <div class="banners-drop-area d-flex flex-column">
    <div v-if="sizeHint" class="text--muted ml-2 mb-1 text-sm" title="Рекомендуемый размер баннеров">
      {{ sizeHint }}
    </div>

    <draggable
      :value="value"
      class="banners-drop-area__drop d-flex flex-column flex-grow-1 pa-2"
      :group="{ name: 'banners', pull: false, put: true }"
      @change="handleBannerPut"
    >
      <template v-if="areBannersFetched">
        <div v-for="(bannerId, i) of value" :key="i" class="banners-drop-area__banner-wrapper">
          <v-img class="flex-grow-0" :src="getBannerImageUrl(bannerId)" height="auto" eager contain />

          <v-btn absolute small icon @click.stop.prevent="removeBanner(i)">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { get } from 'vuex-pathify'
  import draggable from 'vuedraggable'
  import { getBannerImageUrl } from '@/views/BannersManagementView/bannersManagement'

  export default {
    name: 'BannersDropArea',
    components: { draggable },

    props: {
      value: {
        type: Array,
        default: () => []
      },
      limit: {
        type: Number,
        default: null
      },
      sizeHint: {
        type: String,
        default: null
      }
    },

    computed: {
      ...get('bannersManagement', {
        bannersIndex: 'bannersIndex'
      }),

      areBannersFetched() {
        return !_.isEmpty(this.bannersIndex)
      }
    },

    methods: {
      getBannerImageUrl(bannerId) {
        const banner = this.bannersIndex[bannerId]
        return banner ? getBannerImageUrl(banner.filename) : undefined
      },

      handleBannerPut(event) {
        const value = [...this.value]

        if (event.added) {
          if (!_.isNil(this.limit) && this.value.length === this.limit) {
            return
          }

          value.splice(event.added.newIndex, 0, event.added.element.id)
        } else if (event.moved) {
          const bannerId = value.splice(event.moved.oldIndex, 1)[0]
          value.splice(event.moved.newIndex, 0, bannerId)
        }

        this.$emit('input', value)
      },

      removeBanner(bannerIndex) {
        const value = [...this.value]
        value.splice(bannerIndex, 1)
        this.$emit('input', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .banners-drop-area {
    .banners-drop-area__drop {
      row-gap: 12px;
      border: 4px dashed rgba(map-get($theme-colors, 'primary'), 0.3);

      .banners-drop-area__banner-wrapper {
        position: relative;

        .v-image {
          cursor: move;
          border: thin solid map-get($material-theme, 'dividers');

          &:hover {
            border-color: map-get($theme-colors, 'primary');
          }
        }

        .v-btn {
          top: 0;
          right: 0;
          z-index: 5;
        }
      }
    }
  }
</style>
