<template>
  <div class="banners-layout d-flex flex-grow-1 mt-10 mb-5 overflow-hidden">
    <div class="banners-layout__content flex-grow-1 d-flex flex-column overflow-y-auto">
      <div class="d-flex justify-space-between align-center">
        <div v-if="!$vuetify.breakpoint.mdAndDown" class="mt-4 mb-5 page-title">
          {{ $t('pages.titles.bannersManagement') }}
        </div>

        <!--<v-btn-toggle v-model="bannerType" mandatory active-class="primary" borderless dark>-->
        <!--  <v-btn :value="BannerType.ADVERTISING" small>Реклама</v-btn>-->
        <!--  <v-btn :value="BannerType.INFOGRAPHICS" small>Инфографика</v-btn>-->
        <!--  <v-btn :value="BannerType.NEWS" small>Новости</v-btn>-->
        <!--</v-btn-toggle>-->
      </div>

      <div class="page-layout-header d-flex justify-space-between mt-7">
        <a
          class="page-layout-header__title text-decoration-none font-weight-bold"
          :href="pageConfiguration.to"
          target="_blank"
        >
          {{ pageConfiguration.title }}
          <v-icon small>mdi-open-in-new</v-icon>
        </a>

        <v-btn
          :disabled="hasNotChanges || isPending"
          :loading="isPending"
          small
          depressed
          color="primary"
          @click="saveBannersPlacing()"
        >
          Применить
        </v-btn>
      </div>

      <v-sheet class="page-layout-wrapper flex-grow-1 mt-7">
        <component
          :is="pageTypeLayoutComponent.cmp"
          :value="placeTypesModel"
          v-bind="pageTypeLayoutComponent.bind"
          @input="handleBannerPlaceTypeUpdate"
        />
      </v-sheet>

      <div class="page-selector d-flex justify-space-evenly flex-wrap">
        <div
          v-for="pageType of bannerTypePageTypes"
          :key="pageType"
          class="page-item"
          :class="{ 'page-item_selected': pageType === bannerPageType }"
        >
          <page-type-preview
            v-ripple
            :banner-type="bannerType"
            :page-type="pageType"
            :active="pageType === bannerPageType"
            class="page-item__preview"
            @click.native="bannerPageType = pageType"
          />

          <div class="mt-2 font-weight-bold">{{ bannerPageTypeConfig[pageType].title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { sync } from 'vuex-pathify'
  import { objectFromKeys } from 'geoportal/src/utils/func'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { BannerType, BannerPlaceType } from '@/enums/aquarius'
  import { BannerPageType, bannerPageTypeConfig, bannerTypeToPageTypes } from '../bannersManagement'
  // import MainPageAdvertisingLayout from './layouts/MainPageAdvertisingLayout'
  import PageWithFooterBannerLayout from './layouts/PageWithFooterBannerLayout'
  // import MainPageInfographicsLayout from './layouts/MainPageInfographicsLayout'
  // import MainPageNewsLayout from './layouts/MainPageNewsLayout'
  import PageTypePreview from '@/views/BannersManagementView/BannersLayout/PageTypePreview'

  const pageLayoutsConfig = {
    [BannerType.ADVERTISING]: {
      // [BannerPageType.MAIN_PAGE]: { cmp: MainPageAdvertisingLayout },
      [BannerPageType.FAQ]: {
        cmp: PageWithFooterBannerLayout,
        bind: { placeType: BannerPlaceType.FAQ_ADVERTISING }
      },
      // [BannerPageType.PORTAL_DESCRIPTION]: {
      //   cmp: PageWithFooterBannerLayout,
      //   bind: { placeType: BannerPlaceType.PORTAL_DESCRIPTION_ADVERTISING }
      // },
      [BannerPageType.ERROR_PAGE]: {
        cmp: PageWithFooterBannerLayout,
        bind: { placeType: BannerPlaceType.ERROR_PAGE_ADVERTISING }
      }
    }
    // [BannerType.INFOGRAPHICS]: {
    //   [BannerPageType.MAIN_PAGE]: { cmp: MainPageInfographicsLayout }
    // },
    // [BannerType.NEWS]: {
    //   [BannerPageType.MAIN_PAGE]: { cmp: MainPageNewsLayout }
    // }
  }

  const placeTypesDefaultModel = objectFromKeys(Object.values(BannerPlaceType), () => [])

  export default {
    name: 'BannersLayout',
    components: { PageTypePreview },
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        BannerType,
        bannerPageTypeConfig,

        placeTypesInitialModel: _.cloneDeep(placeTypesDefaultModel),
        placeTypesModel: _.cloneDeep(placeTypesDefaultModel)
      }
    },

    computed: {
      ...sync('bannersManagement', {
        bannerType: 'bannerType',
        bannerPageType: 'bannerPageType'
      }),

      pageTypeLayoutComponent() {
        return pageLayoutsConfig[this.bannerType][this.bannerPageType]
      },

      bannerTypePageTypes() {
        return bannerTypeToPageTypes[this.bannerType]
      },

      pageConfiguration() {
        return bannerPageTypeConfig[this.bannerPageType]
      },

      hasNotChanges() {
        return _.isEqual(this.placeTypesModel, this.placeTypesInitialModel)
      }
    },

    created() {
      this.$bus.on('bannersManagement/bannerDeleted', this.handleBannerDeleted)

      this.fetchBannersPlacing()
    },

    methods: {
      setBannersPlacing(data) {
        this.placeTypesInitialModel = _.mapValues(_.keyBy(data, 'type'), (place) => _.map(place.banners, 'id'))
        this.placeTypesModel = _.cloneDeep(this.placeTypesInitialModel)
      },

      async fetchBannersPlacing() {
        const result = await this.$api.backend.banners.get_banner_places()
        this.setBannersPlacing(result)
      },

      handleBannerDeleted() {
        this.placeTypesInitialModel = _.cloneDeep(placeTypesDefaultModel)
        this.placeTypesModel = _.cloneDeep(placeTypesDefaultModel)
        this.fetchBannersPlacing()
      },

      handleBannerPlaceTypeUpdate(value) {
        this.placeTypesModel = { ...this.placeTypesModel, ...value }
      },

      @handlePendingTask()
      async saveBannersPlacing() {
        const changedPlaces = _.pickBy(
          this.placeTypesModel,
          (banners, placeType) => !_.isEqual(banners, this.placeTypesInitialModel[placeType])
        )
        const requestData = Object.entries(changedPlaces).map(([type, banners]) => ({ type, banners }))
        const result = await this.$api.backend.banners.set_banner_places(requestData)
        this.setBannersPlacing(result)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .banners-layout {
    $banners-layout-shadow-blur-radius: 23px;

    display: flex;
    margin-left: calc(15% - #{$banners-layout-shadow-blur-radius});
    margin-right: calc(10% - #{$banners-layout-shadow-blur-radius});

    @media (max-width: 1600px) {
      margin-left: $banners-layout-shadow-blur-radius;
      margin-right: $banners-layout-shadow-blur-radius;
    }

    .v-btn-toggle {
      .v-btn {
        &:not(.v-btn--active) {
          background: map-get($theme-colors, 'secondary');
        }

        &:not(:first-child) {
          border-left-width: 2px;
        }
      }
    }

    .banners-layout__content {
      padding-left: $banners-layout-shadow-blur-radius;
      padding-right: $banners-layout-shadow-blur-radius;

      .page-layout-header {
        .page-layout-header__title {
          font-size: 24px;
          line-height: 28px;
        }
      }

      .page-layout-wrapper {
        min-height: 500px;
        box-shadow: 0 4px $banners-layout-shadow-blur-radius rgba(0, 0, 0, 0.25);
        padding: 16px 8px 16px 16px;

        .page-layout {
          min-height: 100%;
          max-height: 100%;
          padding-right: 8px;
          overflow-y: auto;

          &::v-deep {
            & > * {
              min-width: 0;
            }

            .page-layout__container {
              background: #f0f0f0;
            }
          }
        }
      }

      .page-selector {
        margin-top: 92px;
        gap: 12px;

        .page-item {
          .page-type-preview {
            cursor: pointer;
          }

          &.page-item_selected {
            &,
            .page-type-preview {
              color: map-get($theme-colors, 'primary');
            }
          }
        }
      }
    }
  }
</style>
